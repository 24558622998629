/* InquiryBoard.module.css */

.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.title {
  margin-bottom: 25px !important;
  color: #333;
}

.loadingText {
  padding: 50px;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}

.list {
  background-color: #fff; /* 배경색 */
  /* box-shadow: 0px 2px 4px rgba(0,0,0,0.1); 그림자 효과 제거 */
}

.listItem {
  cursor: pointer;
  padding: 8px 16px; /* 추가된 패딩 */
}

.button {
  margin-top: 20px;
  background-color: #1976d2; /* 기본 버튼 색상 */
  color: white;
}

.button:hover {
  background-color: #115293; /* 호버 시 버튼 색상 */
}

.listItemTitle {
  display: block;
  text-wrap: nowrap;
  white-space: nowrap;
  align-items: center; /* 내용 정렬 */
  max-width: 80%; /* 조정된 최대 너비 */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem !important; /* 폰트 크기 조정 */
}

.listItemDate {
  display: flex;
  align-items: center; /* 내용 정렬 */
  max-width: 80%; /* 조정된 최대 너비 */
  font-size: 0.875rem !important; /* 폰트 크기 조정 */
}

.header {
  display: flex;
  text-wrap: nowrap;
  white-space: nowrap;
  align-items: center; /* 내용 정렬 */
  font-size: 0.875rem !important; /* 폰트 크기를 14px로 설정 */
}

/* 폰트 크기 조정 */
.typography {
  display: flex;
  font-size: 0.875rem !important; /* 폰트 크기를 14px로 설정 */
}

.header {
  background-color: #f5f5f5; /* 헤더 배경 색상 설정 */
  padding: 5px 0; /* 헤더 패딩 설정 */
  align-items: center; /* 내용을 세로 중앙에 배치 */
}
