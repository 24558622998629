.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px; /* 최대 컨테이너 너비를 더 넓게 설정 */
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.stepList {
  width: 100%; /* 전체 너비 */
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px; /* 각 스텝 간격 */
}

.description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px; /* 텍스트와 이미지 간격 */
  text-align: center;
}

.image {
  width: 90%; /* 부모 컨테이너의 90%를 차지 */
  max-width: 800px; /* 이미지 최대 너비를 더 크게 설정 */
  height: auto;
  border: 1px solid #ddd; /* 테두리 */
  border-radius: 8px; /* 둥근 모서리 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}
