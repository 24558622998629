.languageSwitcherContainer {
  position: relative;
  width: 100%;
  padding: 5px 0px;
  color: white;
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  display: flex;
  justify-content: flex-end; /* 버튼을 우측에 정렬 */
  align-items: center;
  z-index: 2; /* 배너의 다른 요소들과 레이어 정렬 */
}

.languageButton {
  width: 120px; /* 고정된 너비 설정 */
  background-color: #007bff; /* 파란색 배경 */
  border: none;
  color: white; /* 흰색 글자 */
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px; /* 모서리를 둥글게 */
  transition: background-color 0.3s ease;
}

.languageButton:hover {
  background-color: #0056b3; /* 호버 시 배경 색상 */
}

.languageMenu {
  position: absolute;
  top: 50px; /* 메뉴가 버튼 아래에 위치하도록 설정 */
  right: 0;
  background-color: white;
  border-radius: 5px; /* 메뉴의 모서리 둥글게 */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  z-index: 10;
  overflow: hidden; /* 내용이 넘치지 않도록 */
}

.languageMenuItem {
  font-size: 12px;
  display: flex; /* Flexbox 사용 */
  justify-content: center; /* 텍스트를 가운데 정렬 */
  align-items: center; /* 텍스트를 수직 가운데 정렬 */
  width: 120px; /* 고정된 너비 설정 */
  padding: 10px;
  cursor: pointer;
  color: #022e5c; /* 파란색 글자 */
  background-color: white;
  text-align: center; /* 텍스트 가운데 정렬 */
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border: none; /* 테두리 제거 */
}

.languageMenuItem:hover {
  background-color: #007bff; /* 호버 시 배경 색상 */
  color: white; /* 호버 시 텍스트 색상 */
}
