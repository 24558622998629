/* TaskManagement.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
}

.actionContainer {
  position: relative;
  display: flex; /* Flexbox 레이아웃을 사용하여 자식 요소를 가로로 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  gap: 10px; /* 버튼 사이의 간격 */
}

.tableContainer {
  width: 80%; /* 테이블 컨테이너의 너비를 전체로 설정 */
  max-width: 500px; /* 최대 너비 제한 */
  display: flex;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}

.container > * + * {
  margin-top: 10px;
}

.title {
  color: #333;
  text-align: center;
}

.draggingStyle {
  background-color: #f4f4f4;
}

.nonDraggingStyle {
  background-color: white;
}

.draggable {
  cursor: move;
}

.noDraggable {
  cursor: not-allowed;
  color: #ccc;
}

.pendingTdFitContent {
  cursor: default;
}

.noPendingTdFitContent {
  cursor: not-allowed;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button {
  margin-right: 5px;
}

.button,
.buttonModify,
.buttonDelete {
  padding: 10px 20px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: block;
  text-wrap: nowrap;
  font-size: 14px;
}

.subButtonModify,
.subButtonDelete {
  padding: 10px 20px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  display: none;
  text-wrap: nowrap;
  font-size: 14px;
}

.button,
.subButtonModify,
.buttonModify,
subButtonModify {
  color: white;
  background-color: #007bff;
}

.button:hover,
.buttonModify:hover,
.subButtonModify:hover {
  color: white;
  background-color: #0056b3;
}

.buttonModify,
.buttonDelete {
  color: white;
  display: block; /* 기본적으로 PC 화면에서 보임 */
}

.buttonDelete,
.subButtonDelete {
  color: white;
  background-color: #dc3545;
}

.buttonDelete:hover,
.subButtonDelete:hover {
  color: white;
  background-color: #c82333;
}

.button:disabled,
.buttonModify:disabled,
.buttonDelete:disabled,
.subButtonDelete:disabled {
  color: white;
  background-color: #ccc;
  cursor: not-allowed;
}

.button:disabled:hover,
.buttonModify:disabled:hover,
.buttonDelete:disabled:hover,
.subButtonDelete:disabled:hover {
  background-color: #ccc;
}

.lastUpdated {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.table {
  margin-top: 10px;
  width: 80%;
  border-collapse: collapse;
}

.th,
.td,
.tdFitContent,
.thFitContent {
  padding: 10px;
  text-align: left;
  font-size: 14px;
  height: 20px;
  text-wrap: nowrap;
  -webkit-user-select: none; /* 텍스트 선택 금지 for Safari */
  user-select: none; /* 텍스트 선택 금지 */
}

.th,
.td {
  min-width: 250px;
  margin-left: 5px;
  margin-right: 5px;
}

.th,
.thFitContent {
  background-color: #f2f2f2;
}

.thFitContent,
.tdFitContent {
  text-align: center;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
  text-wrap: nowrap;
}

.tr:hover {
  background-color: #f5f5f5;
}

/* 모바일 메뉴 스타일 */
.mobileMenuButton {
  display: none; /* 기본적으로 숨김 */
  cursor: pointer;
  border: 1px solid #333;
  background-color: white;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
}

.mobileMenuButton:hover {
  background-color: #f5f5f5;
}

.mobileMenuButton:disabled {
  cursor: not-allowed;
  background-color: #eee;
}

.mobileMenuButton:disabled:hover {
  background-color: #eee;
}

.mobileMenu {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    padding: 8px;
  }

  .tableContainer {
    max-width: 700px; /* 최대 너비 제한 */
  }

  .th,
  .td,
  .thFitContent,
  .tdFitContent {
    margin-left: 5px;
    margin-right: 5px;
    text-wrap: nowrap;
    font-size: 10px;
  }

  .th,
  .td {
    max-width: 70px;
    min-width: auto;
    overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
    text-overflow: ellipsis; /* 내용이 넘칠 경우 말줄임표로 표시 */
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .buttonModify,
  .buttonDelete {
    padding: 5px 10px;
    font-size: 14px;
    display: none; /* 모바일에서는 기존 버튼 숨김 */
  }

  .subButtonModify,
  .subButtonDelete {
    padding: 5px 10px;
    font-size: 14px;
    display: block; /* 모바일에서는 기존 버튼 숨김 */
  }

  .mobileMenu {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    top: 100%;
    z-index: 100;
    width: 100%;
    border-radius: 5px;
  }

  .mobileMenuButton {
    max-width: 50px;
    display: block; /* 모바일에서만 보임 */
  }
}

@media (max-width: 480px) {
  .button {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }

  .tableContainer {
    max-width: 420px; /* 최대 너비 제한 */
  }

  .th,
  .td,
  .thFitContent,
  .tdFitContent {
    margin-left: 5px;
    margin-right: 5px;
    text-wrap: nowrap;
    font-size: 10px;
  }

  .th,
  .td {
    max-width: 70px;
    min-width: auto;
    overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
    text-overflow: ellipsis; /* 내용이 넘칠 경우 말줄임표로 표시 */
  }

  .buttonModify,
  .buttonDelete {
    padding: 5px 10px;
    font-size: 14px;
    display: none; /* 모바일에서는 기존 버튼 숨김 */
  }

  .mobileMenu {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    top: 100%;
    z-index: 100;
    width: 100%;
    border-radius: 5px;
  }

  .subButtonModify,
  .subButtonDelete {
    padding: 5px 10px;
    font-size: 12px;
    display: block; /* 모바일에서는 기존 버튼 숨김 */
  }

  .mobileMenuButton {
    max-width: 50px;
    display: block; /* 모바일에서만 보임 */
  }
}
