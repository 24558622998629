/* JoinIn.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 650px; /* 최대 너비를 600px로 제한 */
    width: 100%; /* 전체 너비 */
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px; /* 모바일 디바이스에서 패딩 조정 */
    }
  }
  
  .textAreaContainer {
    width: 100%; /* 너비 100% */
    margin-bottom: 20px; /* 하단 마진 */
    border: 1px solid #ccc; /* 경계선 스타일 */
    overflow-y: auto; /* 내용이 많을 경우 스크롤바 자동 생성 */
    height: 300px; /* 고정 높이 */
  }
  
  .agreementLabel {
    display: flex;
    align-items: center; /* 체크박스와 라벨을 세로 중앙 정렬 */
    margin-bottom: 15px; /* 라벨 간격 */
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-around; /* 버튼 간격 */
    width: 100%; /* 버튼 그룹 너비 */
  }
  
  .button {
    padding: 10px 20px; /* 버튼 패딩 */
    cursor: pointer; /* 포인터 커서 스타일 */
    border: none; /* 경계선 없음 */
    background-color: #007BFF; /* 배경색 */
    color: white; /* 텍스트 색상 */
    border-radius: 5px; /* 경계선 둥글게 */
  }
  
  .button:disabled {
    background-color: #6c757d; /* 비활성화된 버튼 색상 */
    cursor: not-allowed; /* 비활성화된 버튼 커서 */
  }

  .button:disabled:hover {
    background-color: #6c757d; /* 비활성화된 버튼 호버 색상 */
  }

  .button:hover {
    background-color: #0056b3; /* 호버 시 배경색 변경 */
  }
  
  .cancelButton {
    background-color: #6c757d; /* 취소 버튼 색상 */
  }
  
  .cancelButton:hover {
    background-color: #545b62; /* 취소 버튼 호버 색상 */
  }
  