.footer {
  background-color: #1d1d1d;
  color: #999;
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* 부모 컨테이너 내에서 상대적인 위치 */
  margin-top: auto; /* flex 컨테이너에서 하단으로 밀림 */
  box-sizing: border-box; /* 여백 계산 문제 방지 */
}

.footer .footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer .footer-row p {
  margin: 15px 15px;
}
