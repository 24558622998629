.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  margin-bottom: 50px;
  padding: 20px;
}

.topSection {
  width: 100%;
}

.sectionWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.evenSection .imageContainer {
  order: 1;
}

.evenSection .textContainer {
  order: 2;
  text-align: left;
}

.oddSection .imageContainer {
  order: 2;
}

.oddSection .textContainer {
  order: 1;
  text-align: right;
}

.imageContainer {
  width: 45%; /* 이미지 컨테이너 너비를 50%에서 45%로 조정 */
  padding: 10px;
  box-sizing: border-box;
}

.imageContainer img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.youtubeContainer {
  width: 80%;
  padding-bottom: 45%; /* 16:9 비율 유지 (9 ÷ 16 * 100) */
  position: relative;
}

.youtubeContainer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.textContainer {
  width: 55%; /* 텍스트 컨테이너 너비를 50%에서 55%로 조정 */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.textContainer p {
  font-size: 17px;
  line-height: 1.25;
}

.textContainer img {
  width: 100%;
  height: auto;
}

.bottomSection {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  text-align: center;
}

.card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.card button:hover {
  background-color: #0056b3;
}

.pricingTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #f9f9f9;
}

.pricingTable th,
.pricingTable td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}

.pricingTable th {
  background: linear-gradient(to bottom, #5c77f0, #1d1f72);
  color: #f0f0f0;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: "Arial", sans-serif;
  font-size: 18px;
}

.pricingTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pricingTable tr:hover {
  background-color: #ddd;
}

.pricingTable button {
  padding: 10px 20px;
  width: 70%;
  background-color: #1976d2;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px auto;
}

.pricingTable button:hover {
  background-color: #115293;
}

/* 모바일 및 중간 크기 화면에서 레이아웃 조정 */

@media screen and (max-width: 768px) {
  .imageContainer,
  .textContainer {
    width: 100%;
    padding: 0;
  }

  .oddSection .imageContainer {
    order: 1;
  }

  .oddSection .textContainer {
    order: 2;
    text-align: right;
  }

  .sectionWrapper {
    flex-direction: column;
  }

  .textContainer {
    padding-top: 20px;
  }

  .pricingTable th,
  .pricingTable td {
    font-size: 12px;
    padding: 6px;
  }

  .pricingTable td {
    text-align: center;
  }

  .pricingTable button {
    padding: 8px 5px;
    font-size: 10px;
  }

  .proSubscriptionRow {
    background-color: #fef9e7;
    font-weight: bold;
  }

  .proSubscriptionRow td {
    vertical-align: top;
  }

  .proBenefits {
    margin-top: 5px;
    padding-left: 20px;
    list-style-type: disc;
  }

  .proBenefits li {
    margin-bottom: 5px;
  }
}
