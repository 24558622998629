/* 기본 스타일 */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  width: 120px;
  margin-right: 10px;
}

.select,
.inputText,
.inputRadio,
.button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.timeInput {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.timeInputLabel {
  margin-right: 10px; /* 라벨과 입력 필드 사이 간격 조정 */
}

.inputText {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.button,
.buttonAddBest,
.buttonDelete {
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button,
.buttonAddBest {
  background-color: #007bff;
  color: white;
}

.buttonDelete {
  color: white;
  background-color: #dc3545;
  height: 30px;
}

.buttonDelete:hover {
  background-color: #c82333;
}

.buttonAddBest {
  width: 100px;
  margin-left: 10px;
}

.inputBest {
  flex: 1;
}

.draggingStyle {
  background-color: #f4f4f4;
}

.nonDraggingStyle {
  background-color: white;
}

.draggable {
  cursor: move;
}

.inputBestContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.inputBest,
.buttonAddBest {
  height: 50px;
}

.button:hover,
.buttonAddBest:hover {
  background-color: #0056b3;
}

.button:disabled,
.buttonAddBest:disabled,
.buttonDelete:disabled {
  background-color: #ccc; /* 비활성화 상태의 배경색 */
  color: #666; /* 비활성화 상태의 텍스트 색상 */
  cursor: not-allowed; /* 커서를 금지 아이콘으로 변경 */
}

.button:disabled:hover,
.buttonAddBest:disabled:hover,
.buttonDelete:disabled:hover {
  background-color: #ccc; /* 비활성화 상태에서 호버 시에도 배경색 유지 */
}

.bestsTable {
  margin-top: 10px;
  width: 100%; /* 테이블이 부모 컨테이너의 너비를 전체 차지하도록 설정 */
  border-collapse: collapse; /* 테이블의 보더를 겹치게 하여 더 깔끔하게 표현 */
}

.bestsTable th,
.bestsTable td {
  padding: 8px; /* 셀 내부에 패딩을 추가 */
  border: 1px solid #ddd; /* 경계선 스타일 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
}

.bestsTable th {
  background-color: #f0f0f0; /* 헤더 배경 색상 */
}

.bestsTable td:nth-child(1),
.bestsTable td:nth-child(3) {
  width: 50px; /* 순위와 삭제 컬럼 너비 고정 */
}

.bestsTable td:nth-child(2) {
  width: auto; /* 내용 컬럼 너비 자동 조정 */
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.labelRadio,
.labelCheckbox {
  display: inline-block;
  margin-right: 5px;
  margin-top: 10px;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .button {
    padding: 8px 10px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .button {
    padding: 6px 8px;
    font-size: 14px;
  }
}
