/* UserMenu.module.css */
.userMenu {
  display: flex;
  align-items: center;
  max-width: 150px;
  padding: 10px;
  border-radius: 5px;
  position: relative; /* 서브 메뉴를 포지셔닝하기 위해 필요 */
  width: auto; /* 콘텐츠에 맞춰 너비 자동 조절 */
  flex-basis: auto; /* 내용에 기반한 기본 크기 */
  flex-grow: 0; /* 필요이상으로 확장되지 않도록 */
  flex-shrink: 1; /* 공간이 부족할 경우 줄어들 수 있도록 함 */
}

.button {
  margin-left: 20px;
  margin-right: 20px;
  min-width: 100px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: #7a3434;
}

.submenuButton {
  width: 100%; /* 모든 버튼이 동일한 너비 */
  padding: 10px 20px;
  margin: 5px 10px; /* 버튼 간 간격 */
  background-color: #ffffff; /* 부드러운 회색 톤 */
  color: #333; /* 다크 그레이 텍스트 */
  border: none;
  border-radius: 8px; /* 둥근 모서리 */
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s; /* 호버 애니메이션 */
}

.submenuButton:hover {
  background-color: #e0e0e0; /* 호버 시 색상 변경 */
}

.menuLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%; /* 버튼 바로 아래 위치하도록 설정 */
  transform: translateX(0%); /* 중앙 정렬 해제 */
  background-color: white; /* 배경색을 흰색으로 설정 */
  overflow: hidden; /* 내용이 넘치는 것을 숨김 */
  transition: all 0.3s ease; /* 부드러운 전환 효과 */
  height: 0; /* 초기 높이 설정 */
  width: auto; /* 내용에 맞춰 너비 조정 */
  max-width: 90%; /* 최대 너비는 부모 너비의 90%를 넘지 않도록 */
  border-radius: 10px; /* 모서리 둥글게 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); /* 그림자 추가 */
  flex-basis: auto; /* 내용에 기반한 기본 크기 */
  flex-grow: 0; /* 필요이상으로 확장되지 않도록 */
  flex-shrink: 1; /* 공간이 부족할 경우 줄어들 수 있도록 함 */
  z-index: 1000;
}

.menuLinks.open {
  height: auto; /* 열린 상태에서 메뉴의 높이를 내용에 맞게 자동 조정 */
  visibility: visible;
  padding: 5px 5px; /* 패딩 추가 */
  margin-left: 5px;
  margin-right: 5px;
}
