.links {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* 줄바꿈 방지 */
}

.link {
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  color: white;
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
  overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
  text-overflow: ellipsis; /* 넘치는 텍스트를 말줄임표로 표시 */
}

.link:last-child {
  margin-right: 0;
}
