/* UserStatus.module.css */
.userStatus {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* 전체 너비 사용 */
  padding: 10px 0px;
  background: linear-gradient(to right, #33348f, #462b72, #6b2121);
  border-bottom: 1px solid #000000;
  max-width: 1280px; /* 최대 너비 설정 */
  margin: auto;
}

.userDetails {
  font-size: 12px;
  display: flex;
  color: white;
  flex-basis: auto; /* 내용에 기반한 기본 크기 */
  flex-grow: 0; /* 내용이 길어질 경우 확장 */
  flex-shrink: 1; /* 공간이 부족할 경우 줄어들 수 있도록 함 */
}

.leftSpacer {
  flex-grow: 1; /* 왼쪽 여백을 채웁니다 */
}

.desktopLinks {
  display: none; /* 기본적으로 숨김, 데스크탑에서만 보임 */
}

/* 미디어 쿼리를 사용하여 데스크탑에서는 NavigationLinks 보이도록 설정 */
@media (min-width: 768px) {
  .desktopLinks {
    display: block;
  }

  .mobileMenu {
    display: none; /* 데스크탑에서는 모바일 메뉴 숨김 */
  }
}
