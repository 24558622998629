/* 전체 요소에 box-sizing 적용 */
* {
  box-sizing: border-box;
}

/* 전체 컨테이너 */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 화면 전체 높이 */
  margin: 0 auto; /* 가운데 정렬 */
  max-width: 1280px; /* 최대 너비 설정 */
  overflow-x: hidden; /* 가로 스크롤 방지 */
}

/* 헤더 */
.header {
  display: flex; /* 플렉스 레이아웃으로 정렬 */
  justify-content: space-between; /* 좌우 정렬 */
  align-items: center; /* 세로 정렬 */
  padding: 10px 20px; /* 내부 여백 */
  background: linear-gradient(to right, #5c77f0, #1d1f72); /* 배경색 설정 */
  color: white; /* 텍스트 색상 */
  width: 100%; /* 부모 컨테이너 너비에 맞춤 */
  margin: 0; /* 외부 여백 제거 */
  box-sizing: border-box; /* padding 포함 */
  overflow-x: hidden; /* 헤더 내에서 넘침 방지 */
}

.header .left-section {
  display: flex;
  align-items: center; /* 세로 정렬 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  flex: 1; /* 남은 공간 차지 */
}

.header .right-section {
  display: flex;
  align-items: center; /* 세로 정렬 */
  justify-content: flex-end; /* 오른쪽 정렬 */
  flex: 1; /* 남은 공간 차지 */
}

.header .right-section a {
  margin-left: 20px; /* 버튼 간격 */
  color: white;
  text-decoration: none; /* 밑줄 제거 */
}

/* 메인 콘텐츠 영역 */
.content {
  display: flex;
  flex: 1; /* 메인 콘텐츠가 남은 공간을 채움 */
  width: 100%;
  overflow-x: hidden; /* 가로 스크롤 방지 */
}

/* 네비게이션 메뉴 */
.nav {
  width: 250px; /* 네비게이션 메뉴 고정 너비 */
  background-color: #f4f4f4;
  padding: 1rem;
  box-sizing: border-box; /* padding 포함 */
  overflow-x: hidden; /* 넘침 방지 */
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0; /* 외부 여백 제거 */
}

.nav li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: left;
}

.nav li:hover,
.nav .active {
  background-color: #ddd;
  font-weight: bold;
}

/* 메인 콘텐츠 */
.main {
  flex: 1; /* 남은 공간을 차지 */
  padding: 2rem;
  overflow-y: auto; /* 세로 스크롤 허용 */
  box-sizing: border-box; /* padding 포함 */
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column; /* 네비게이션과 콘텐츠를 세로로 배치 */
    overflow-x: hidden; /* 가로 스크롤 방지 */
  }

  .nav {
    width: 100%; /* 네비게이션 메뉴 너비를 100%로 설정 */
    margin-bottom: 20px; /* 콘텐츠와의 간격 */
  }

  .main {
    padding: 1rem;
  }
}
