/* InquiryDetail.module.css */

.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9; /* 밝은 회색 배경 */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  margin-bottom: 30px !important;
  color: #333;
}

.subject {
  font-size: 1.1rem !important; /* 타이틀 크기 감소 */
  color: #333; /* 짙은 회색 */
  margin-top: 20px;
  width: 100%;
}

.grid {
  margin-top: 20px;
}

/* InquiryDetail.module.css */
.replyButton {
  display: block;
  margin-top: 8px; /* 버튼과 텍스트 사이의 간격 */
  align-self: flex-end; /* 우측 정렬 */
}

.flex-container {
  display: flex;
  justify-content: flex-end;
}

.info {
  display: flex;
  justify-content: flex-end; /* 오른쪽 정렬 */
  width: 100%; /* 너비를 컨테이너에 맞춤 */
  margin-bottom: 10px;
}

.author,
.date {
  font-size: 0.8rem;
  color: #666;
  margin-left: 20px !important; /* 라벨 간 간격 */
}

.content,
.replyItem {
  box-sizing: border-box; /* 너비 계산 시 패딩과 테두리 포함 */
  white-space: pre-line; /* 줄바꿈을 그대로 표시 */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%; /* 너비를 컨테이너에 맞춤 */
}

.content {
  font-size: 0.9rem;
  min-height: 150px;
  line-height: 1.6;
  color: #444;
}

.replies {
  width: 100%; /* 답글 리스트의 너비를 컨테이너에 맞춤 */
}

.replyItem {
  font-size: 0.9rem;
  color: #555;
}

.replyContent {
  font-size: 1rem;
  color: #555;
}

.replyMeta {
  font-size: 0.8rem;
  color: #777;
  margin-top: 5px;
}

.deleteButton {
  /*우측에 위치하도록 설정*/
  margin-left: auto;
  color: #f00;
  cursor: pointer;
}
