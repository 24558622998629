/* PrivacyPolicy.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
}

.header {
  width: 90%;
  max-width: 800px;
  text-align: left;
}

.title {
  /* For h1 tags */
  font-size: 32px; /* Larger font size */
  font-weight: bold;
}

.subTitle {
  /* For h2 tags */
  font-size: 24px; /* Medium font size */
  font-weight: bold;
}

.sectionTitle {
  /* For h3 tags */
  font-size: 18px; /* Smaller font size */
  font-weight: bold;
}

.text,
.subText {
  width: 90%;
  max-width: 800px;
  text-align: left;
}

.list {
  width: 90%;
  max-width: 800px;
  padding-left: 20px;
  list-style: disc inside;
}

.link {
  color: #0077cc;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.item {
  width: 90%;
  max-width: 800px;
}
