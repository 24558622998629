/* MobileMenu.module.css */
.mobileMenu {
  display: none;
  position: relative;
  width: 100%; /* 전체 너비를 유지 */
  min-width: 60px; /* 최소 너비 설정 */
  align-items: flex-start; /* 좌측 정렬 */
}

.menuLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%; /* 버튼 바로 아래 위치하도록 설정 */
  left: 0; /* 왼쪽 가장자리에서 시작 */
  transform: translateX(0%); /* 중앙 정렬 해제 */
  background-color: white; /* 배경색을 흰색으로 설정 */
  overflow: hidden; /* 내용이 넘치는 것을 숨김 */
  transition: all 0.3s ease; /* 부드러운 전환 효과 */
  height: 0; /* 초기 높이 설정 */
  width: auto; /* 내용에 맞춰 너비 조정 */
  max-width: 90%; /* 최대 너비는 부모 너비의 90%를 넘지 않도록 */
  border-radius: 10px; /* 모서리 둥글게 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); /* 그림자 추가 */
  z-index: 1000;
}

.submenuButton {
  width: 100%; /* 모든 버튼이 동일한 너비 */
  padding: 12px 20px; /* 충분한 패딩으로 편안한 클릭 */
  margin: 5px 0; /* 버튼 간 간격 */
  background-color: #ffffff; /* 부드러운 회색 톤 */
  color: #333; /* 다크 그레이 텍스트 */
  border: none;
  border-radius: 8px; /* 둥근 모서리 */
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-wrap: nowrap;
  transition: background-color 0.2s; /* 호버 애니메이션 */
}

.submenuButton:hover {
  background-color: #e0e0e0; /* 호버 시 색상 변경 */
}

.menuButton {
  padding: 10px;
  border-radius: 10px; /* 모서리 둥글게 설정 */
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px; /* 좌측 여백 추가 */
}

.menuButton:hover {
  background-color: #4b4b8a; /* 버튼 호버 시 배경색 변경 */
}

@media (max-width: 768px) {
  .menuLinks {
    left: 0; /* 좌측 정렬 */
    max-width: 150px; /* 최대 너비 제한 없음 */
  }

  .submenuButton {
    padding: 10px 12px; /* 패딩 축소 */
    font-size: 13px; /* 폰트 크기 축소 */
  }

  .mobileMenu {
    display: flex;
    width: 50px;
    flex-direction: column;
    align-items: flex-start; /* 버튼이 항상 좌측에 정렬되도록 설정 */
  }
}

.menuLinks.open {
  height: auto; /* 열린 상태에서 메뉴의 높이를 내용에 맞게 자동 조정 */
  visibility: visible;
  padding: 10px 20px; /* 패딩 추가 */
}
