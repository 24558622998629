/* LoginEmail.css */
.login-email-page .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.login-email-page .login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.login-email-page .login-input, .login-email-page .login-button {
    width: 300px; /* 너비를 동일하게 설정 */
    box-sizing: border-box; /* 패딩과 보더를 너비에 포함 */
    padding: 10px; /* 상하 패딩 조정 */
    margin: 10px 0; /* 상하 마진 설정, 좌우 마진은 제거 */
    font-size: 16px;
    border-radius: 4px;
}

.login-email-page .login-input {
    border: 1px solid #ccc;
}

.login-email-page .login-button {
    color: white;
    background-color: #007BFF;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center; /* 버튼 내 텍스트 중앙 정렬 */
    align-items: center; /* 세로 방향 중앙 정렬 */
    transition: background-color 0.3s; /* 배경색 변경 애니메이션 */
}

.login-email-page .login-button:hover {
    background-color: #0056b3; /* 호버 시 배경색 변경 */
}
