/* InquiryCreate.module.css */
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh; /* 변경된 부분 */
  background-color: #f4f4f4;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.title {
  margin-bottom: 20px;
}

.form {
  width: 100%; /* 너비를 100%로 설정하여 form 내의 모든 요소가 컨테이너 너비에 맞게 조정됩니다. */
  max-width: 600px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.subjectField,
.contentField {
  /* 공통 스타일 적용을 위해 선택자 통합 */
  width: 100%; /* 전체 너비 */
  padding: 8px; /* 패딩 일치 */
  font-size: 1rem; /* 폰트 사이즈 일치 */
  border-radius: 4px; /* 테두리 둥글기 일치 */
  box-sizing: border-box; /* 박스 사이징 */
}

.subjectField {
  border: 1px solid transparent; /* 테두리가 없어도 박스 크기에 포함되도록 설정 */
}

.contentField {
  border: 1px solid #ccc; /* 기존 테두리 설정 유지 */
}

.button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
