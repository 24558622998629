.myPage {
  color: #333;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
}

.userDetails {
  display: grid;
  grid-template-columns: 150px 1fr; /* 헤더와 값에 대한 열 너비 설정 */
  gap: 10px; /* 그리드 간격 */
  margin-bottom: 20px;
  background: #ffffff; /* 배경색 설정 */
  border-radius: 5px; /* 모서리 둥글게 */
  padding: 10px; /* 내부 패딩 */
}

.userDetails div {
  padding: 5px; /* 각 셀의 패딩 */
}

.legalLinks,
.accountDeletion {
  margin-top: 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.legalLinks a {
  color: #555;
  margin-right: 10px; /* 또는 적절한 여백 값 */
  text-decoration: none;
}

.accountDeletion a {
  color: #e40000;
  text-decoration: none;
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  flex-direction: column; /* 버튼을 세로로 정렬 */
}

.buttonGroup button,
.buttonGroup a {
  width: 100%; /* 너비 100% */
  margin-top: 10px; /* 마진 */
  padding: 10px;
  text-align: center;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* 링크의 밑줄 제거 */
}

.buttonGroup a {
  background: #ff4d4d; /* 링크 배경 색상 */
}

.buttonGroup button:hover,
.buttonGroup a:hover {
  background-color: #115293; /* 버튼과 링크의 호버 배경색 */
}
