/* Login.css */
.login-page .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* 전체 높이 */
  }
  
  .login-page .login-button {
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px 20px; /* 패딩 조정으로 버튼 크기 증가 */
    border: none;
    border-radius: 5px; /* 경계선 둥글게 */
    font-size: 16px; /* 글씨 크기 */
    cursor: pointer;
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  
  .login-page .login-button img {
    width: 25px; /* 이미지 크기 조정 */
    margin-right: 10px;
  }
  
  .login-page .google-button {
    background-color: white;
    color: black;
  }
  
  .login-page .google-button:hover {
    background-color: #f1f1f1; /* 호버 시 배경색 변경 */
  }
  
  .login-page .email-button {
    background-color: red;
    color: white;
  }
  
  .login-page .email-button:hover {
    background-color: darkred; /* 호버 시 배경색 변경 */
  }
  