.container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 컨텐츠를 위쪽으로 정렬 */
  height: 100vh;
  background-color: #f9f9f9; /* 배경색은 필요에 따라 변경하세요 */
  padding-top: 200px; /* 화면 상단으로부터 간격 */
}

.main {
  text-align: center; /* 텍스트를 가운데 정렬 */
}

.downloadSection {
  display: flex;
  flex-direction: column;
  align-items: center; /* 수직 방향 중앙 정렬 */
  gap: 20px; /* 이미지와 텍스트 사이 간격 */
}

.downloadImage {
  max-width: 200px; /* 이미지 크기 조정 */
  cursor: pointer;
  transition: transform 0.2s;
}

.downloadImage:hover {
  transform: scale(1.1);
}

.description {
  font-size: 16px;
  color: #333;
}
